import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ClassesPage = () => {
  const data = useStaticQuery(graphql`
    query ClassesPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            classes
          }
        }
      }
      classes01: file(relativePath: { eq: "classes-hero-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles } = data.site.siteMetadata
  const { classes01 } = data

  return (
    <Layout>
      <Seo title={`${siteTitle} | ${pageTitles.about}`} />
      <BackgroundImage
        Tag="section"
        {...convertToBgImage(getImage(classes01))}
        className="hero classes01"
        role="parallax"
        preserveStackingContext
      >
        <div className="wrapper">
          <h1>
            I want you to be everything that's you,
            <br /> deep at the center of your being.
          </h1>
          <h2>&mdash; Confucius</h2>
        </div>
      </BackgroundImage>
      <section className="textual">
        <div className="wrapper">
          <h1>{pageTitles.classes}</h1>
          <div className="block twothirdcol">
            <div className="block col1">
              <blockquote>
                Experience the Joy of Tai Chi through our series of focused
                on-site sessions. Our eight-week programs are tailored towards
                recovery, rehabilitation, and senior movement. Strengthen your
                phsyical and mental connection with each Tai Chi session.
              </blockquote>
              <div id="beginners" />
              <hr />
              <h3>Beginners Class</h3>
              <p>
                Our Beginners Class is structured for the true beginner in mind,
                those with no prior experience in Tai Chi or Qigong. This class
                will introduce you to the relaxing, meditative posture of the
                art. You will learn the Ying and Yang reverse breathing
                techniques as well as a six movement form. This class is
                designed not only for the beginner but for those recovering from
                physical and/or mental stress. This class is static and students
                can be seated or standing.
              </p>
              <p>
                <a
                  className="btn"
                  href="mailto:joyce@joyoftaichi.com?subject=Beginners%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Beginners%20Class."
                >
                  Sign up
                </a>
              </p>
              <div id="energies" />
              <hr />
              <h3>4 Energies Class</h3>
              <p>
                Our second level class is structured for those who have some
                prior knowledge of the breathing and posture of the art of tai
                chi, whether you have taken our level 1 class or had training
                elsewhere. Relaxation, breath work , posture and balance will be
                incorporated into the teaching of the for energies movements;
                Peng, Lu, Ji and Ang. Exploring how Tai Chi energy moves in the
                body through breath, movement and relaxation.
              </p>
              <p>
                <a
                  className="btn"
                  href="mailto:joyce@joyoftaichi.com?subject=Energies%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Energies%20Class."
                >
                  Sign up
                </a>
              </p>
              <div id="form24" />
              <hr />
              <h3>Form 24</h3>
              <p>
                The Yang style Tai Chi form know as 24 is the national form of
                China and the most widely know Tai Chi form in the world. This
                class will give you a brief the history of the form, as well as
                walk you slowly through it's movements. This class requires a
                completion of the beginners class and/or prior knowledge and
                practice of Yang style Tai Chi. This class is non-static and
                taught in a standing position with breath, meditation and
                balance work incorporated. Our 4 energies class is not required
                but recommended.
              </p>
              <p>
                <strong>Level 1</strong>
                <br />
                Breath, Balance, Meditation and movements 1-8
              </p>
              <p>
                <strong>Level 2</strong>
                <br />
                Breath, Balance, Meditation and movements 9-16
              </p>
              <p>
                <strong>Level 3</strong>
                <br />
                Breath, Balance, Meditation and movements 17-24
              </p>
              <p>
                <a
                  className="btn"
                  href="mailto:joyce@joyoftaichi.com?subject=Form%2024%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Form%2024%20Class."
                >
                  Sign up
                </a>
              </p>
              <div id="private" />
              <hr />
              <h3>Individual Private Classes</h3>
              <p>
                Our private classes are tailored to the individuals' needs and
                desires. They include but are not limited to meditation, breath
                work, balance, Qigong and Tai Chi forms. Please speak to Shifu
                Joyce, via email or phone about your needs and for scheduling.
              </p>
              <p>
                <a
                  className="btn"
                  href="mailto:joyce@joyoftaichi.com?subject=Individual%20%2F%20Private%20Class&amp;body=Hello.%20I'm%20interested%20in%20your%20Individual%20%2F%20Private%20Class."
                >
                  Sign up
                </a>
              </p>
            </div>
            <div className="block col2">
              <nav>
                <a href="#beginners">Beginners Class</a>
                <a href="#energies">Energies Class</a>
                <a href="#form24">Form 24 Class</a>
                <a href="#private">Individual Private Classes</a>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ClassesPage
